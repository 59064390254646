<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <div v-for="(file,index) in form.defaultList" :key="file.id" class="resourceList">
        <div class="index">{{ index + 1 }}</div>
        <div class="index">
          <ys-file-list :fileList="[file]" :width="70" :height="70" type="onlyFile"></ys-file-list>
        </div>
        <div>
          <FormItem label="资源类型" prop="resourceType">
            <Select v-model="file.resourceType" disabled placeholder="资源类型" transfer>
              <Option v-for="(item,index) in baseData['资源类型']" :value="item.value" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="附件类型" prop="type">
            <Select v-model="file.type" placeholder="附件类型" transfer>
              <Option v-for="(item,index) in typeObj" :value="item.value" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="文件名" prop="fileName">
            <Input v-model="file.fileName" placeholder="文件名"></Input>
          </FormItem>
          <FormItem label="描述" prop="desc">
            <Input v-model="file.desc" placeholder="描述"></Input>
          </FormItem>
        </div>
        <div class="index">
          <Button icon="md-trash" v-if="modData.length===0" @click="delFile(index)"></Button>
        </div>
      </div>
      <ys-upload v-if="modData.length===0" @on-success="bLFile" :headers="{token:token}" ref="upload" :showFile="false" type="fullList" :defaultList.sync="form.defaultList" action="/web/resource/UploadFile"></ys-upload>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'projectEdit',
  components: {
    ysUpload: () => import('../../components/ys-upload'),
  },
  props: {
    modData: {type: Array, default: () => []},
    typeObj: {type: Array, default: () => []},
    resourceType: {type: Number, default: 1},
    resourceId: {type: Number, default: null,required:true}
  },
  computed: {
    baseData: function () {
      return window.baseData;
    },
    token: function () {
      return sessionStorage.getItem('token')
    }
  },
  data() {
    return {
      ruleValidate: {},
      form: {
        resourceId:'',
        defaultList: []
      },
    }
  },
  created() {
  },
  mounted() {
    this.modal = this.$parent.$parent;
    this.modData.forEach(({...item}) => {
      let fileName = item.fileName.split('.')
      if (fileName.length > 1) {
        item.fileName = item.fileName.substring(0, item.fileName.length - fileName[fileName.length - 1].length - 1);
        item.suffix = item.suffix || fileName[fileName.length - 1];
      }
      item.resourceId = this.resourceId;
      this.form.defaultList.push(item)
    });
  },
  methods: {
    close() {
      this.modal.modShow = false;
    },
    save() {
      this.modal.spinShow = true;
      let ajaxList = []
      this.form.defaultList.forEach(({...item}) => {
        item.fileName = item.fileName + '.' + item.suffix
        delete item.response;
        ajaxList.push(this.$post('/resource/file/Edit', item));
      })
      this.$all(ajaxList).then(() => {
        this.close();
        this.modal.spinShow = false;
        this.$emit('on-ok');
      }).catch(() => {
        this.modal.spinShow = false;
      })
    },
    delFile(index) {
      this.form.defaultList.splice(index, 1)
    },
    bLFile(data) {
      this.form.defaultList = [];
      data.forEach(({...item}) => {
        let fileName = item.fileName.split('.')
        if (fileName.length > 1) {
          item.resourceId = this.resourceId;
          item.resourceType = this.resourceType;
          item.type = this.typeObj.length > 0 ? this.typeObj[0].value : '';
          item.fileName = item.fileName.substring(0, item.fileName.length - fileName[fileName.length - 1].length - 1);
          item.suffix = item.suffix || fileName[fileName.length - 1];
        }
        this.form.defaultList.push(item)
      });
    },
    ok(url, nextType) {
      this.modal.spinShow = true;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$post(url, this.form).then((res) => {
            this.modal.spinShow = false;
            if (res.code === 200) {
              this.close();
              this.$Notice.success({
                title: "提示！",
                desc: '操作成功！'
              });
              this.$emit('on-ok', res.data, nextType);
            }
          }).catch(() => {
            this.modal.spinShow = false;
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.modal.spinShow = false;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
